import {
  ErrorComponent,
  UserDetailsComponent,
  UserDetailsForm,
} from "Components/Shared";
import PageNotFound from "Components/Shared/PageNotFound";
import VideoListDetailsComponent from "Components/Shared/VideoListComponent/Details";
import Batches from "Pages/Batches";
import AddBatch from "Pages/Batches/AddBatch";
import BatchDetails from "Pages/Batches/BatchDetails";
import EditBatch from "Pages/Batches/EditBatch";
import Coaches from "Pages/Coaches";
import Dashboard from "Pages/Dashboard";
import DashboardDetails from "Pages/Dashboard/dashboardDetails";
import OLDDrills from "Pages/OLD-Drills";
import DrillsDetails from "Pages/OLD-Drills/DrillsDetails";
import ForgotPassword from "Pages/ForgotPassword";
import ResetPassword from "Pages/ForgotPassword/ResetPassword";
import Login from "Pages/Login";
import Players from "Pages/Players";
import PromotionalVideos from "Pages/PromotionalVideos";
import PromotionalVideoSubmissions from "Pages/PromotionalVideos/PromotionalVideoSubmissions";
import PromotionalVideoSubmissionDetail from "Pages/PromotionalVideos/PromotionalVideoSubmissionDetail";
import Reports from "Pages/Reports";
import PlayerSurveyAdherenceDetailsPage from "Pages/Reports/PlayerSurveyAdherenceDetailsPage";
import Sports from "Pages/Sports";
import AddSports from "Pages/Sports/AddSports";
import SurveyQuestions from "Pages/SurveyQuestions";
import Academy from "Pages/Academies";
import AddAcademy from "Pages/Academies/AddAcademy";
import EditAcademy from "Pages/Academies/EditAcademy";
import AddAdmin from "Pages/Admin/AddAdmin";
import EditAdmin from "Pages/Admin/EditAdmin";
import AddSurveyQuestion from "Pages/SurveyQuestions/AddSurveyQuestion";
import EditSurveyQuestion from "Pages/SurveyQuestions/EditSurveyQuestion";
import SurveyQuestionDetails from "Pages/SurveyQuestions/SurveyQuestionDetails";
import Drills from "Pages/Drills";
import PublicAsset from "Pages/PublicAsset";
import ShowAcademy from "../Components/Shared/AcademyDetailsComponent";
import Notifications from "Pages/Notifications";
import PromotionalVideoDetail from "Pages/PromotionalVideos/PromotionalVideoDetail";
import DrillVideoDetail from "Pages/Drills/DrillVideoDetail";
import DrillVideoSubmissions from "Pages/Drills/DrillVideoSubmissions";
import DrillVideoSubmissionDetail from "Pages/Drills/DrillVideoSubmissionDetails";

export const PUBLIC_ROUTES = [
  {
    Component: Login,
    path: "/",
  },
  {
    Component: ForgotPassword,
    path: "/forgot-password",
  },
  {
    Component: ErrorComponent,
    path: "/error",
  },
  {
    Component: ResetPassword,
    path: "reset-password",
  },
  {
    Component: PageNotFound,
    path: "/page-not-found",
  },
];

export const PRIVATE_ROUTES = [
  {
    Component: Batches,
    path: "/batches",
    resourceType: "Batch",
    action: "index",
  },
  {
    Component: Coaches,
    path: "/coaches",
    resourceType: "Coach",
    action: "index",
  },
  {
    Component: Dashboard,
    path: "/dashboard",
    resourceType: "Dashboard",
    action: "index",
  },
  {
    Component: Drills,
    path: "/drills",
    resourceType: "Drill",
    action: "index",
  },
  {
    Component: DrillVideoDetail,
    path: "/drills/details/:id",
    resourceType: "Drill",
    action: "index",
  },
  {
    Component: DrillVideoSubmissions,
    path: "/drills/submissions",
    resourceType: "Drill",
    action: "index",
  },
  {
    Component: DrillVideoSubmissionDetail,
    path: "/drills/submissions/:id",
    resourceType: "Drill",
    action: "index",
  },
  {
    Component: OLDDrills,
    path: "/old-drills",
    resourceType: "Drill",
    action: "index",
  },
  {
    Component: Players,
    path: "/players",
    resourceType: "Player",
    action: "index",
  },
  {
    Component: Notifications,
    path: "/notifications",
    resourceType: "notification",
    action: "index",
  },
  {
    Component: Reports,
    path: "/reports",
    resourceType: "report",
    action: "index",
  },
  {
    Component: PlayerSurveyAdherenceDetailsPage,
    path: "/reports/player-survey-adherence/:playerId",
    resourceType: "report",
    action: "index",
  },
  {
    Component: PromotionalVideos,
    path: "/featured-videos",
    resourceType: "featured_asset",
    action: "index",
  },
  {
    Component: PromotionalVideoSubmissions,
    path: "/featured-videos/submissions",
    resourceType: "featured_asset",
    action: "index",
  },
  {
    Component: PromotionalVideoSubmissionDetail,
    path: "/featured-videos/submissions/:id",
    resourceType: "featured_asset",
    action: "index",
  },
  {
    Component: PromotionalVideoDetail,
    path: "/featured-videos/:id",
    resourceType: "featured_asset",
    action: "index",
  },
  {
    Component: PublicAsset,
    path: "/public-asset",
    resourceType: "public_asset",
    action: "index",
  },
  {
    Component: VideoListDetailsComponent,
    path: "/public-assets/details/:id",
    resourceType: "public_asset", // todo get the role assigned from backend
    action: "index",
  },
  {
    Component: Sports,
    path: "/sports",
    resourceType: "MasterSport",
    action: "index",
  },
  {
    Component: SurveyQuestions,
    path: "/survey-questions",
    resourceType: "SurveyQuestion",
    action: "index",
  },
  {
    Component: Academy,
    path: "/academies",
    resourceType: "Academy",
    action: "index",
  },
  {
    Component: AddAcademy,
    path: "/academies/new",
    resourceType: "Academy",
    action: "create",
  },
  {
    Component: EditAcademy,
    path: "/academies/:id/edit",
    resourceType: "Academy",
    action: "create",
  },
  {
    Component: AddAdmin,
    path: "/academies/:academy_id/admin/new",
    resourceType: "Admin",
    action: "create",
  },
  {
    Component: AddSurveyQuestion,
    path: "/survey-questions/new",
    resourceType: "SurveyQuestion",
    action: "create",
  },
  {
    Component: EditSurveyQuestion,
    path: "/survey-questions/:id/edit",
    resourceType: "SurveyQuestion",
    action: "update",
  },
  {
    Component: SurveyQuestionDetails,
    path: "/survey-questions/:id",
    resourceType: "SurveyQuestion",
    action: "show",
  },
  {
    Component: UserDetailsForm,
    path: "/players/new",
    resourceType: "Player",
    action: "create",
  },
  {
    Component: UserDetailsForm,
    path: "/players/:id/edit",
    resourceType: "Player",
    action: "update",
  },
  {
    Component: UserDetailsComponent,
    path: "/players/:id",
    resourceType: "Player",
    action: "show",
  },
  {
    Component: VideoListDetailsComponent,
    path: "/players/:id/asset/:asset_id",
    resourceType: "Player",
    action: "show",
  },
  {
    Component: AddSports,
    path: "/sports/new",
    resourceType: "MasterSport",
    action: "index",
  },
  {
    Component: UserDetailsForm,
    path: "/coaches/new",
    resourceType: "Coach",
    action: "create",
  },
  {
    Component: UserDetailsForm,
    path: "/coaches/:id/edit",
    resourceType: "Coach",
    action: "update",
  },
  {
    Component: UserDetailsComponent,
    path: "/coaches/:id",
    resourceType: "Coach",
    action: "show",
  },
  {
    Component: AddBatch,
    path: "/batches/new",
    resourceType: "Batch",
    action: "create",
  },
  {
    Component: EditBatch,
    path: "/batches/:id/edit",
    resourceType: "Batch",
    action: "update",
  },
  {
    Component: BatchDetails,
    path: "/batches/:id",
    resourceType: "Batch",
    action: "show",
  },
  {
    Component: DrillsDetails,
    path: "/old-drills/:id/details",
    resourceType: "Drill",
    action: "show",
  },
  {
    Component: DashboardDetails,
    path: "/dashboard/:id",
    resourceType: "Dashboard",
    action: "index",
  },
  {
    Component: ShowAcademy,
    path: "/academies/:id",
    resourceType: "Academy",
    action: "show",
  },
  {
    Component: EditAdmin,
    path: "/academies/:academy_id/admin/:id/edit",
    resourceType: "Admin",
    action: "edit",
  },
];
