import React, { useCallback, useEffect, useState } from "react";
import { Form } from "Components/Core";
import {
  SearchControl,
  PaginationControl,
  TableComponent,
} from "Components/Shared";
import {
  getNotificationsList,
  updateNotifications,
} from "Api/Pages/NotificationsApi";
import { useAppDispatch, useAppSelector } from "App/hooks";
import {
  getColumns,
  NOTIFICATIONS,
  NotificationType,
} from "Pages/Notifications/constants";
import { NotificationsObjectType } from "Interfaces/Pages/NotificationsInterface";
import { INITIAL_PAGE, RECORDS_PER_PAGE } from "App/constants";
import { setNotificationCount } from "Pages/Notifications/notificationReducer";
import { fetchNotificationCount } from "Api/Pages/NotificationsApi";
import { useNavigate } from "react-router-dom";

const Notifications = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [notificationsList, setNotificationsList] = useState<
    NotificationsObjectType[]
  >([]);
  const [filteredList, setFilteredList] = useState<NotificationsObjectType[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState<number>(INITIAL_PAGE);
  const [totalPages, setTotalPages] = useState<number>(INITIAL_PAGE);
  const [searchInput, setSearchInput] = useState<string>("");
  const {
    userDetails: { academy_id, permissions },
  } = useAppSelector((state) => state.loginReducer);

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event;
      setSearchInput(value);
      setCurrentPage(INITIAL_PAGE);
    },
    [setSearchInput, setCurrentPage]
  );

  const fetchNotificationsList = useCallback(async () => {
    const list = await getNotificationsList(
      academy_id,
      currentPage,
      searchInput
    );
    const total_count = list?.total_count;
    const data = list?.admin_notifications;
    const totalPages = Math.ceil(total_count / RECORDS_PER_PAGE);

    setTotalPages(totalPages);
    setNotificationsList(data);
    setFilteredList(data);
  }, [academy_id, currentPage, searchInput]);

  const updateRecordsStatus = useCallback(async () => {
    const unreadNotificationListIds: number[] = [];
    for (const notification of notificationsList) {
      if (notification.is_read === false) {
        unreadNotificationListIds.push(notification.id);
      }
    }
    await updateNotifications(academy_id, unreadNotificationListIds);

    const count = await fetchNotificationCount(academy_id);
    dispatch(setNotificationCount(count));
  }, [notificationsList, academy_id, dispatch]);

  useEffect(() => {
    fetchNotificationsList();
  }, [academy_id, currentPage, searchInput, fetchNotificationsList]);

  useEffect(() => {
    if (notificationsList.length > 0) {
      updateRecordsStatus();
    }
  }, [notificationsList, updateRecordsStatus]);

  const handleOnRowClick = (data: NotificationsObjectType) => {
    if (data.notification_type === NotificationType.PLAYER_PROFILE_UPDATE) {
      navigate(`/players/${data.entity_id}`);
    }

    if (data.notification_type === NotificationType.VIDEO_APPROVAL_REQUEST) {
      if (data.metadata.upload_purpose === "featured") {
        navigate(
          `/featured-videos/submissions/${data.metadata.approval_asset_id}`
        );
      } else {
        navigate(`/drills/submissions/${data.metadata.approval_asset_id}`);
      }
    }
  };

  return (
    <div className="ss-page-content d-flex flex-column flex-1 scroll-y bg-white">
      <header className="ss-page-header d-flex align-items-center justify-content-between px-4">
        <h2 className="fs-md mb-0 fw-medium">{NOTIFICATIONS}</h2>
      </header>
      <hr className="ss-page-seprator mx-4 my-0" />
      <div className="ss-page-body d-flex flex-column flex-1 scroll-y py-4">
        <Form className="d-flex justify-content-end mb-4 px-4">
          <SearchControl
            className="mt-4"
            placeholder="Search"
            label="Search"
            onChange={handleSearch}
          />
        </Form>
        <div className="flex-1 scroll-y px-4">
          <div className="table-responsive">
            <TableComponent
              columns={getColumns(permissions)}
              tableData={filteredList}
              isClickAble={false}
              onRowClick={handleOnRowClick}
            />
          </div>
          <div className="pagination-wrap d-flex justify-content-end px-4">
            <PaginationControl
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
