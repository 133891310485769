import { useEffect } from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import { Toast, ToastContainer } from "react-bootstrap";
import { createBrowserHistory } from "history";
import { Loader, ConfirmBoxComponent } from "Components/Shared";
import { SUCCESS } from "App/constants";
import { store } from "App/store";
import { AppLayout } from "App/AppLayout";
import { useAppDispatch, useAppSelector } from "App/hooks";
import { setToastMessage } from "App/commonReducer";
import axiosInterceptor from "Utils/axiosInterceptor";

import "bootstrap/dist/css/bootstrap.min.css";

const queryClient = new QueryClient();
export const history = createBrowserHistory();
axiosInterceptor.config(store, history);

export default function App() {
  const { toastMessage, showLoader, confirmProps } = useAppSelector(
      (state) => state.commonReducer
    ),
    dispatch = useAppDispatch(),
    isSuccessMessage = toastMessage[1] && toastMessage[1] === SUCCESS,
    alertMessageArray = toastMessage;
  useEffect(() => {
    if (toastMessage.length) {
      setTimeout(() => {
        dispatch(setToastMessage([]));
      }, 3000);
    }
  }, [toastMessage, dispatch]);

  return (
    <>
      {confirmProps.showConfirm && <ConfirmBoxComponent {...confirmProps} />}
      <QueryClientProvider client={queryClient}>
        {AppLayout()}
      </QueryClientProvider>

      {toastMessage && toastMessage.length ? (
        <ToastContainer className="p-3" position="top-end">
          <Toast
            onClose={() => {
              dispatch(setToastMessage([]));
            }}
            show={true}
            bg={isSuccessMessage ? "success" : "danger"}
          >
            <Toast.Header closeButton={isSuccessMessage ? false : true}>
              <strong className="me-auto">
                {isSuccessMessage
                  ? toastMessage[0].toString()
                  : Array.isArray(alertMessageArray)
                  ? alertMessageArray.map((message: string, index: number) => {
                      return <div key={index}>{message}</div>;
                    })
                  : alertMessageArray}
              </strong>
            </Toast.Header>
          </Toast>
        </ToastContainer>
      ) : (
        ""
      )}
      <Loader showLoader={showLoader} />
    </>
  );
}
