import { BASE_URL } from "App/constants";
import axios from "axios";

export const getNotificationsList = async (
  academyId: number,
  currentPage: number,
  searchInput: string
) => {
  const { data } = await axios.get(
    `${BASE_URL}/notifications?page=${currentPage}&search=${searchInput}`
  );
  return data ?? [];
};

export const updateNotifications = async (
  academyId: number,
  unreadNotificationListIds: number[]
) => {
  const { data } = await axios.put(`${BASE_URL}/notifications/update_status`, {
    notificatons_ids: unreadNotificationListIds,
  });

  if (data) return data;
};

export const fetchNotificationCount = async (academy_id: number) => {
  const { data } = await axios.get(`${BASE_URL}/notifications/unread_count`);

  return data?.unread_notification_count ?? 0;
};
