import { MatchPlayedProps } from "Interfaces/Components/Shared/DisplayListComponentInterface";
import { COACH, EMPTY_LABEL, PLAYER } from "App/constants";
import { AssetType } from "../AssetLandingPage/types";
import TextWrapper from "../TextWrapper";
import { formatDate, formatTimestamp } from "Utils/helper";

export const PERSONAL_DETAILS: string = "personalDetails";
export const SURVEY_HISTORY: string = "surveyHistory";
export const PLAYER_DETAILS: string = "Player Details";
export const PERSONAL_DETAILS_TITLE: string = "Personal Details";
export const SURVEY_HISTORY_TITLE: string = "Survey History";
export const MATCH_PLAYED_TITLE: string = "Match Played";
export const VIDEOS: string = "Videos";
export const COMMENTS: string = "Comments";
export const PLAYER_TITLE: string = "player";
export const MORNING: string = "Morning";

export const UserTypeMap: Record<string, string> = {
  [COACH]: "coaches",
  [PLAYER]: "players",
};

export const getMatchPlayedColumns = () => [
  {
    dataField: "name",
    title: "Opponent Name",
    formatter: (props: MatchPlayedProps) => {
      return props.opponent_details.name;
    },
  },
  {
    dataField: "rank",
    title: "Rank",
    formatter: (props: MatchPlayedProps) => {
      return `${props.opponent_details.rank}${props.opponent_details.sub_rank}`;
    },
  },
  {
    dataField: "date",
    title: "Date",
  },
  {
    dataField: "timing",
    title: "Time",
  },
  {
    dataField: "venue",
    title: "Venue",
  },
  {
    dataField: "result",
    title: "Match Status",
  },
];

export const NO_BATCH_ASSIGNED: string = "No Batch Assigned";

export const COMMENT_TABLE_HEADERS = [
  {
    dataField: "text",
    title: "Comment",
    colWidth: "40%",
  },
  {
    dataField: "commentor_type",
    title: "Commented By",
    colWidth: "10%",
  },
  {
    dataField: "created_at",
    title: "Commented on",
    colWidth: "10%",
    formatter: (data: AssetType) => {
      return data.created_at ? (
        <TextWrapper width={120} tooltipText={formatDate(data.created_at)}>
          {formatTimestamp(data.created_at)}
        </TextWrapper>
      ) : (
        EMPTY_LABEL
      );
    },
  },
];
